.tot-avg-rating h1 span {
    font-size: 30px;
    color: #9E9E9E;
}

.avg-rating {
    font-size: 45px !important;
}

#review-btn-post-review {
    padding: 6px 20px;
    border: none;
    background: #559EFC;
    color: white;
    box-shadow: 0 0 2px black;
}

.prod-review-ribbon {
    border-top: 1px solid #EFF0F5;
    border-bottom: 1px solid #EFF0F5;
    padding: 10px 20px;
}

.prof-name-txt {
    font-weight: 600;
    font-size: 20px;
    color: #9E9E9E;
}

.head-prof-img {
    width: 50px;
    border-radius: 50% !important;
    overflow: hidden;
    aspect-ratio: 1/1;
}

.head-prof-img img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.prod-review-list {
    overflow-y: scroll;
    height: 600px;
}

.prod-review-list-item {
    border-bottom: 1px solid #EFF0F5;
}
