.rsc-float-button {
    transform: scale(1.2) !important;
    position: fixed !important;
    bottom: 40px !important;
    right: 40px !important;
    overflow: hidden !important;
    animation: blinking 1s ease infinite;
}

.rsc-float-button img {
    padding-top: 22px !important;
    height: 90px !important;
    width: 75% !important;
}

.rsc-ts-bot .rsc-ts-image-container {
    position: relative;
    overflow: hidden !important;
}

.rsc-ts-bot .rsc-ts-image {
    height: 50px;
}

@keyframes blinking {
    0% {
        border: 5px solid #0259A7;
    }
    100% {
        background-color: #0259A7;
        border: 5px solid #57c7f3;
    }
}
