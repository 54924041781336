.dashboard-banner {
  background-color: #585ce5 !important;
  background-image: url(../../../images/illustration.png);

  background-repeat: no-repeat;
  background-size: auto 85%;
  background-position-x: right;
  background-position-y: bottom;
  height: 100px;

  .card-body {
    background-color: rgba($color: #000000, $alpha: 0.3);
  }

  .btn-primary {
    background-color: #063b9d !important;
    border-color: #063b9d !important;
    padding: 10px 40px;
  }
}

.text-lg {
  font-size: 48px;
}

.text-md {
  font-size: 20px;
}

.contact-us-section {
  padding: 60px 0 !important;

  h2 {
    font-size: 48px;
  }

  p {
    font-size: 20px;
  }

  .enquiry-form {
    display: flex;
    gap: 20px;
    padding-top: 75px;

    .info-section {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 40px;
      padding: 32px;
      border-radius: 16px;
      background-color: rgb(2, 89, 167);
      flex-basis: 400px;
      -webkit-box-flex: 1;
      flex-grow: 1;
      color: white;
      box-shadow: rgba(60, 64, 67, 0.3) 0px 10px 20px 0px,
        rgba(60, 64, 67, 0.15) 0px 12px 16px 2px;

      h3 {
        font-size: 32px;
      }

      p {
        font-size: 16px;
      }

      div {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }

      div:nth-child(2) {
        gap: 32px;
      }

      div:last-child {
        display: flex;
        justify-content: start;
        flex-direction: row;
        align-items: center;
        gap: 15px;

        div {
          width: 40px !important;
          height: 40px !important;
          align-items: center;
          justify-content: center;
        }
      }
    }

    .form-section {
      padding: 32px;
      background-color: white;
      min-width: 700px;
      background-color: rgb(250, 250, 250);
      border-radius: 15px;

      fieldset {
        border: 0px !important;
        border-bottom: 1.3px solid !important;
        border-color: rgba(0, 0, 0, 0.23) !important;
      }

      .submit-btn {
        text-align: right;
        padding-top: 48px;
      }
    }
  }

  .css-mhc70k-MuiGrid-root > .MuiGrid-item {
    padding-left: 40px;
    padding-top: 40px;
  }

  .css-4lkm7v-MuiGrid-root {
    flex-basis: 40%;
  }

  .css-480o17-MuiGrid-root > .MuiGrid-item {
    padding-left: 0;
  }
}

@media (min-width: 900px) {
  .css-5vl4uj-MuiContainer-root {
    max-width: 1200px !important;
  }
}

.corporate-profile-table {
  th {
    width: 20%;
    text-transform: capitalize;

    &::after {
      content: "  :";
    }
  }

  td {
    text-transform: capitalize;
  }
}

.update-map {
  div {
    padding: 12px 16px;
    border: 1px solid #333;
    background-color: #f9f9f9;
    outline: none;
    margin-top: 16px;
    max-width: fit-content;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    font-size: 14px;
    color: #333;
  }
}

.tooltip-container {
  position: relative;
  display: inline-block;
}

.tooltip-container .tooltip-text {
  visibility: hidden;
  width: 220px;
  background-color: #024889;
  color: #fff;
  text-align: center;
  padding: 5px 10px;
  border-radius: 16px;
  position: absolute;
  z-index: 10000;
  bottom: 110%;
  left: -50%;
  transform: translateX(-50%) translateY(-10px);
  opacity: 0;
  transition: opacity 0.3s ease, transform 0.3s ease;
  font-size: 14px;
}

.tooltip-container:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
  transform: translateX(-50%) translateY(0);
}

.services-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  padding-bottom: 48px;

  &-items {
    background-color: #fff;
    color: rgba(0, 0, 0, 0.87);
    border-radius: 4px;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
      0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    overflow: hidden;
    border-radius: 15px;
    height: 100%;
    padding: 16px 16px 24px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    justify-content: stretch;
  }

  .service-image {
    width: 100%;
    overflow: hidden;
    aspect-ratio: 1.6/1;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 300ms;

    &:hover {
      transform: scale(1.2);
    }
  }

  a {
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    background-color: transparent;
    outline: 0;
    border: 0;
    margin: 0;
    border-radius: 0;
    padding: 0;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    vertical-align: middle;
    -moz-appearance: none;
    -webkit-appearance: none;
    -webkit-text-decoration: none;
    text-decoration: none;
    color: inherit;
    font-family: "Source Sans Pro", sans-serif;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.75;
    text-transform: uppercase;
    min-width: 64px;
    padding: 6px 16px;
    border-radius: 4px;
    -webkit-transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    color: #fff;
    background-color: #1976d2;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
      0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    min-width: 60%;
    border-radius: 50px;
    margin-top: auto;

    &:hover {
      background-color: #1565c0;
    }
  }
}

.service-grid-title {
  font-size: 1.25rem !important;
  text-align: center;
  font-weight: bold;
}

.service-grid-description {
  text-align: center;
}

.all-services-page {
  li {
    font-size: 24px;
  }
}
