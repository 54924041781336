.booking-payment {
    padding-left: 20px;
    padding-right: 20px;
}

.booking-payment .card-header {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background-color: white;
}

.booking-payment img {
    width: 130px;
}

.booking-payment button {
    border: none;
    background-color: transparent;
}

.booking-payment span {
    color: #0259A7;
    font-weight: 600;
}

@media only screen and (min-width: 768px) {
    .booking-payment {
        padding-left: 30px;
        padding-right: 30px;
    }

    .booking-payment .col-md-2 {
        border-right: 2px solid rgba(0, 18, 33, 0.1);
    }
}

@media only screen and (min-width: 1000px) {
    .booking-payment .col-md-2 {
        border-right: 2px solid rgba(0, 18, 33, 0.1);
    }

    .booking-payment {
        padding-left: 50px;
        padding-right: 50px;
    }
}
