.package-page {
  background: radial-gradient(circle, rgba(13, 89, 168, 0.6) 0%, rgba(198, 223, 246, 1) 95%);

  .package-header {
    h1 {
      font-weight: 700;
      margin: 0;
      padding: 0;
    }

    h4 {
      margin-top: 10px;
      font-size: 18px;
      font-weight: 600;
    }
  }
}

.pg-head-txt {
  span {
    font-size: 22px;
    font-weight: 700;
  }
}

.pg-title {
  span {
    font-size: 25px;
  }

}

.pg-reg-fee {
  font-size: 14px;

  .pg-reg-value {
    font-weight: 600;
  }
}

.pg-label {
  font-weight: 600;
}

.pg-border {
  border-bottom: 1px solid #C7C8C9;
}

.pg-description {
  ul {
    margin: 0;
    padding: 0;
    margin-left: 18px;
    font-size: 15px;

    li {
      line-height: 25px;
    }
  }
}

.package-item {
  background: #fff;
  padding: 20px 60px;
  border-radius: 10px;

  &.center-package {
    background: #094381;
    color: white;

    @media only screen and (min-width: 1024px) {
      -webkit-transform: scale(1.15);
      -moz-transform: scale(1.15);
      transform: scale(1.15);
    }

    // margin-bottom: 80px;
  }

  &.recommended {
    position: relative;
  }

  .pg-recommended {
    box-sizing: border-box;
    position: absolute;
    top: -15px;
    width: 50%;
    left: 25%;
    background: radial-gradient(circle, rgba(57, 96, 213, 1) 12%, rgba(136, 169, 238, 1) 100%);
    color: white;
    text-align: center;
    z-index: 99;
  }

  & > div {
    padding: 4px 0;
  }

  .pg-info-desc {
    span {
      font-size: 18px;
      color: #0D59A8;
      font-weight: 600;
    }
  }
}
