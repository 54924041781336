.upload-file-container {
  .slick-slider {
    height: 100% !important;
  }

  .slick-list {
    height: 100% !important;
  }

  .slick-track {
    height: 100% !important;
  }

  .slick-slide {
    height: 100% !important;
  }

  .slick-slide > div {
    height: 100% !important;
  }

  .slick-slide > div > * {
    height: 100% !important;
  }
}
