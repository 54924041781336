.editProfile .PhoneInputCountry, .editProfile .PhoneInputInput {
    padding: .375rem 1rem;
    height: 39px;
    border: 0.906977px solid rgba(102, 102, 102, 0.35);
    border-radius: 0.25rem;
    /* margin-bottom:15px; */
}

.editProfile .PhoneInputInput:focus {
    color: #212529;
    background-color: #fff;
    border-color: #86b7fe;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
}

.editProfile p {
    color: #bf1650;
    margin-bottom: 10px;
    margin-top: 0;
}

.editProfile p::before {
    display: inline;
    content: "⚠ ";
}

.editProfile .viewFileUpload iframe, .imgUpload {
    /* height: 200px; */
}

.editProfile .image-input input {
    display: none
}

.editProfile .image-input label {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
}
