.latest-products {
  .latest-products-list {
    // box-shadow: 0 0 10px #D2D2D2;
    .latest-prod-card {
      width: 100%;
      aspect-ratio: 1/1.2 !important;
      border: none;
      box-shadow: 0 4px 10px #D2D2D2;
      // padding: 0 10px;

      .card-header {
        border: none;
        background: inherit;
        height: 65%;
        padding: 1px;

        .latest-prod-img {
          height: 100%;
          width: 100%;

          .img-div {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }
        }
      }

      .card-body {
        height: 35%;
        overflow: hidden;
      }
    }
  }


}

.custom-slick {
  .slick-slide > div {
    margin: 0 10px;
  }

  .slick-list {
    // margin: 0 -10px;

    .slick-track {
      margin-left: 0 !important;
    }
  }
}

