.report .report-btn button {
    border-radius: 0 !important;
    text-align: left;
}

.report button.active {
    background-color: #1976d2 !important;
}

.report .fontStyle {
    font-weight: 600 !important;
}

.report p.fontStyle, .report th.fontStyle {
    color: #1976d2;
}

.report p.fontStyle {
    text-align: right;
}

.report .nav-pills {
    margin-bottom: 0;
}
