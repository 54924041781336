.team .team-content-section {
    background: #F9F9F9;
    padding: 30px 30px 60px;
}

.team .team-container {

}

.team .bg-team {
    background-image: linear-gradient(292deg, rgba(20, 69, 128, .81) 0%, rgba(20, 69, 128, .85) 100%), url(https://doctorsoncall.com.np/wp-content/uploads/2020/09/business-03-1.jpg) !important;
    background-repeat: no-repeat;
    background-size: cover;
    color: white !important;
}

.team .breadcrumb-item {
    font-size: 18px;
}

.team .breadcrumb-item a {
    color: #C6DFF6;
    text-decoration: none;
}

.team .breadcrumb-item a:hover {
    color: #C6DFF6;
    opacity: 0.8;
}

.team .breadcrumb-item.active {
    color: #949698;
}

/* .team .breadcrumb-item{
    padding-bottom: 2rem;
    amr
} */

.team h1, .team h3 {
    font-weight: 600;
}

.team .card, .team .card-body {
    border-radius: 0 !important;
    border: none;
    background-color: inherit;
}

/*
.team .member-card{
    position: relative;
} */

.team .member {
    border-top: 8px solid #0259A7;
    padding: 20px;
    /* width: 80%; */
    /* position: absolute;
    bottom:-35px;
    left:10%; */
}

.team .member h4 {
    color: #0259A7;
    font-size: 20px;
    font-family: 'Montserrat', Helvetica, Arial, Lucida, sans-serif;
}

.team .member p {
    font-family: 'Montserrat', Helvetica, Arial, Lucida, sans-serif;
}
