.Trusted-Vendors {
    background: #57c7f3;
}

.heading h1 {
    font-weight: 700;
    font-size: 45px;
    line-height: 100%;
}

.heading p {
    margin-top: 10px;
    font-weight: 400;
    font-size: 18px;
    line-height: 110%;
}

.trusted-vendor-row {
    padding-top: 80px;
}

.card-img {
    background: #79d2f5;
    border-radius: 38px;
    height: 224px;
}

.trusted-vendor-row .card {
    background: #57c7f3;
    border: none;
}

.trusted-vendor-img {
    margin-left: 10px;
    margin-top: -55px;
    filter: drop-shadow(0px 4px 17px rgba(0, 0, 0, 0.2));
}

.trusted-vendor h5 {
    font-weight: 600;
    font-size: 30px;
    line-height: 64px;
    text-align: center;
}

.trusted-vendor p {
    font-weight: 600;
    font-size: 18px;
    line-height: 120%;
    text-align: center;
    color: #ffffff;
    opacity: 0.7;
}

.trusted-vendor-gym-img {
    position: relative;
    left: 50px;
    bottom: 91px;
    right: -35px;
    filter: drop-shadow(0px 4px 17px rgba(0, 0, 0, 0.2));
    transform: matrix(1, 0, 0, 1, 0, 0);
    height: 295px;
}

.trusted-vendor-yoga-img {
    position: relative;
    left: 50px;
    height: 295px;
    bottom: 85px;
    filter: drop-shadow(0px 4px 17px rgba(0, 0, 0, 0.2));
}

.btn-text {
    font-weight: 500;
    font-size: 24px;
    line-height: 130%;
}

.trusted-vendor .btn-primary {
    padding: 12px 79px;
}

.vendor-slider {
    display: none;
}

@media screen and (max-device-width: 1024px) {
    .card-img {
        background: #79d2f5;
        border-radius: 38px;
        width: 291px;
        height: 224px;
    }

    .trusted-vendor .card {
        background: #57c7f3;
        border: none;
        padding: 90px 100px 0;
        margin-left: 10px;
        margin-right: 10px;
    }

    .organic .trusted-vendor-img {
        margin-left: 13px;
        margin-top: -43px;
        width: 272px !important;
        height: 225px !important;
        filter: drop-shadow(0px 4px 17px rgba(0, 0, 0, 0.2));
    }

    .trusted-vendor-gym-img {
        position: absolute;
        bottom: 334px;
        left: 13px;
        height: 271px;
        width: 260px;
    }

    .trusted-vendor-yoga-img {
        position: absolute;
        bottom: 268px;
        margin-left: -6px;
        height: 339px;
        width: 250px;
    }
}

@media screen and (max-device-width: 768px) {
    .slider-card-img {
        background: #79d2f5;
        border-radius: 38px;
        height: 224px;
    }

    .trusted-vendor-slider .card {
        background: #57c7f3;
        border: none;
        padding: 90px 100px 0;
        margin-left: 10px;
        margin-right: 10px;
    }

    .trusted-vendor-row {
        display: none !important;
    }

    .vendor-slider {
        margin-top: 15px;
        bottom: 15px;
        display: block;
    }

    .Trusted-Vendors .slick-prev {
        z-index: 1;
    }

    .slider-trusted-vendor-img {
        position: absolute;
        top: 40px;
        margin-left: 30px;
    }

    .slider-trusted-vendor-gym-img {
        position: absolute;
        top: -2px;
        left: 180px;
        height: 295px;
    }

    .slider-trusted-vendor-yoga-img {
        position: absolute;
        bottom: 251px;
        right: 185px;
        margin-left: 35px;
        height: 300px;
    }
}

@media screen and (max-device-width: 425px) {
    .slider-card-img {
        background: #79d2f5;
        border-radius: 38px;
        width: 390px;
        height: 224px;
    }

    .trusted-vendor-slider .card {
        background: #57c7f3;
        border: none;
        padding: 0;
        margin-top: 90px;
    }

    .trusted-vendor-row {
        display: none !important;
    }

    .vendor-slider {
        margin-top: 15px;
        bottom: 15px;
        display: block;
    }

    .Trusted-Vendors .slick-prev {
        z-index: 1;
    }

    .slider-trusted-vendor-img {
        margin-right: -7;
        position: absolute;
        top: -50px;
        left: -35px;
    }

    .slider-trusted-vendor-gym-img {
        position: absolute;
        left: 29px;
        top: -92px
    }

    .slider-trusted-vendor-yoga-img {
        position: absolute;
        top: -90px;
        left: 10px;
    }
}

@media screen and (max-device-width: 375px) {
    .slider-card-img {
        background: #79d2f5;
        border-radius: 38px;
        /* width: 337px; */
        width: 315px;

    }

    .trusted-vendor-slider .card {
        background: #57c7f3;
        border: none;
        margin: 90px 10px;
    }

    .trusted-vendor-row {
        display: none !important;
    }

    .vendor-slider {
        margin-top: 15px;
        bottom: 15px;
        display: block;
    }

    .Trusted-Vendors .slick-prev {
        z-index: 1;
    }

    .slider-trusted-vendor-img {
        position: absolute;
        top: -49px;
        left: -37px;
        height: 254px;
        width: 319px;
    }

    .slider-trusted-vendor-gym-img {
        position: absolute;
        /* right: -28px; */
        top: -96px;
        left: 0px;
    }

    .slider-trusted-vendor-yoga-img {
        position: absolute;
        bottom: 271px;
        left: -21px;
        /* padding: 0 10px; */
    }
}

@media screen and (max-device-width: 320px) {
    .slider-card-img {
        background: #79d2f5;
        border-radius: 38px;
        width: 290px;
        height: 170px;
    }

    .trusted-vendor-slider .card {
        background: #57c7f3;
        border: none;
        margin: 82px 0;
    }

    .trusted-vendor-row {
        display: none !important;
    }

    .vendor-slider {
        margin-top: 15px;
        bottom: 15px;
        display: block;
    }

    .Trusted-Vendors .slick-prev {
        z-index: 1;
    }

    .slider-trusted-vendor-img {
        position: absolute;
        top: -35px;
        left: -43px;
        height: 183px;
        padding: 0 10px;
        margin-right: 0;
    }

    .slider-trusted-vendor-gym-img {
        position: absolute;
        right: 15px;
        top: -98px;
    }

    .slider-trusted-vendor-yoga-img {
        position: absolute;
        bottom: 293px;
        left: -35px;
    }
}
