.product-details {
  .product-images {
    border: 1px solid #D6D6D6;
    padding: 60px 0 30px 0;
    box-sizing: border-box;
    border-radius: 10px;

    .product-main-img {
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 100%;
        aspect-ratio: 1/1;
        object-fit: contain;
      }
    }

  }

  .product-name,
  .product-price {
    h2 {
      font-weight: 600;
    }
  }

  .product-price {
    h2 {
      color: #75D3F6;
    }

    .discount {
      border: 1px solid #D6D6D6;
      padding: 2px 10px;
      border-radius: 20px;
      background-color: #FAFAFA;
      font-size: 14px;
    }
  }

  .product-rating {
    padding: 10px 40px;
    background: #e3eff3;
    color: #0D59A8;
    font-size: 18px;
    font-weight: 600;
  }

  .product-add-details {
    font-size: 18px;

    .left-label {
      color: #777777;
    }

    .right-info {
      color: #0D59A8;
      font-weight: 600;
    }

    .product-quantity {
      .prod-quan-stepper {
        width: 150px;
      }

      .alert {
        font-size: 14px;
        padding: 5px 20px;
      }

    }
  }

  .buy-btn {
    color: #75D3F6 !important;
    border-color: #75D3F6 !important;
  }

  .cart-btn {
    color: white !important;
    background-color: #75D3F6 !important;
  }
}
