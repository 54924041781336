.appointment-detail {
  .card {
    padding: 20px;
    border: none;
  }

  .doctor-profile {
    .profile-image {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 55%;
        aspect-ratio: 1/1;
        border-radius: 50%;
        object-fit: cover;
      }
    }
  }

  .doctor-profile-details {
    .profile-label {
      font-size: 1.1rem;
    }

    .profile-detail {
      font-size: 1rem;
    }
  }

  .appointment-list {
    table {
      border-collapse: separate;
      border-spacing: 0 15px;

      tr {
        background-color: white;
      }
    }

    max-height: 200px;
    overflow-y: scroll;
  }

  .payment-details {
    .pay-with {
      .payment-item {
        width: 120px;
        height: 60px;

        img {
          height: 100%;
          width: 100%;
          object-fit: contain;
        }

        button {
          border: none;
          background: none;
        }
      }
    }
  }

  .document-list {
    max-height: 200px;
    overflow-y: scroll;

    .file-name-col {
      width: 40%;
    }

    .file-name {
      // width: 60%;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      /* number of lines to show */
      line-clamp: 1;
      -webkit-box-orient: vertical;
    }
  }

  .review-section {
    .review-header {
      padding: 20px 0;
      border-top: 2px solid #58CAF4;
      border-bottom: 1px solid #DFE2EA;

      .star-ic {
        color: gold;
      }
    }

    .reviews-container {
      border-bottom: 2px solid #58CAF4;
    }

    .review-ribbon {
      border-bottom: 1px solid #DFE2EA;
    }

    .main-reviews {
      border-left: 1px solid #DFE2EA;
    }


  }

  .review-item {
    .review-top {

      .review-user-image {
        flex-basis: 10%;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;

        img {
          width: 100%;
          aspect-ratio: 1/1;
          object-fit: cover;
          border-radius: 5px;
        }
      }

      .review-date,
      .ml-auto {
        margin-left: auto;
      }
    }
  }

}
