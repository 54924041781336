.booking-form {
    background-color: #E9ECF4;
}

.booking-form .card {
    border-radius: 10px;
}

.booking-form .card-header {
    background-color: white;
}

.booking-form .doctor-profile .card-header {
    border-top-left-radius: 10px;
}

.booking-form .doctor-profile .card-header, .booking-form .doctor-profile .card-body .profile-description {
    border-bottom: 2px solid rgba(0, 18, 33, 0.1);
}

.booking-form .doctor-profile .booking-heading span {
    font-size: 20px;
}

.booking-form .doctor-profile .booking-heading span, .booking-form .patient-details h4 {
    color: #0259A7;
    font-weight: 600;
}

.booking-form .doctor-profile .booking-heading img {
    height: 30px;
}

.booking-form .doctor-image {
    width: 150px;
    height: 150px;
    overflow: hidden;
    border-radius: 50%;
}

.booking-form h5 {
    font-weight: 600;
}

.booking-form .doctor-profile h5 {
    color: #0259A7;
}

.booking-form ul li {
    line-height: 170%;
}

.booking-form ul .time {
    color: #0259A7;
    font-weight: 600;
    font-size: 16px;
}

.booking-form .form p {
    color: black;
    font-weight: 600;
    margin-bottom: 10.8px;
}

.booking-form .form .col-md-12 {
    border-bottom: 2px solid rgba(0, 18, 33, 0.1);
    padding-left: 15px;
    padding-right: 15px;
}

.booking-form .patient-details .card-header {
    padding-left: 15px;
    padding-right: 15px;
}

@media only screen and (min-width: 768px) {
    .booking-form .form .col-md-12 {
        padding-left: 20px;
        padding-right: 20px;
    }

    .booking-form .patient-details .card-header {
        padding-left: 20px;
        padding-right: 20px;
    }

    .booking-form .doctor-profile {
        border-right: 2px solid rgba(0, 18, 33, 0.1);
    }
}

@media only screen and (min-width: 1000px) {
    .booking-form .form .col-md-12 {
        padding-left: 40px;
        padding-right: 40px;
    }

    .booking-form .patient-details .card-header {
        padding-left: 40px;
        padding-right: 40px;
    }

    .booking-form .doctor-profile {
        border-right: 2px solid rgba(0, 18, 33, 0.1);
    }
}
