.package-details-page {
  .package-details {
    // background-color: #57C7F3;
    // background: url(../../../images/pattern1.png);
    background: linear-gradient(
        151deg,
        rgba(13, 89, 168, 0.5) 0%,
        rgba(254, 254, 254, 1) 100%
      ),
      url(../../../images/pattern1.png);
  }

  .section-title {
    h1 {
      font-weight: 700;
    }

    border-bottom: 2px solid #efefef;
  }

  .package-content {
    background: #0259a6;
    border-radius: 10px;
    overflow: hidden;
    width: 85%;
    margin: auto;

    .package-detail {
      .package-item {
        background: inherit !important;
        color: white;
      }
    }

    .package-fee-calculator-outer {
      padding: 5px;
      box-sizing: border-box;

      .package-fee-calculator-inner {
        background: #fff;
        border-radius: 10px;
        box-sizing: border-box;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .package-fee-calculator {
          // background: #fff;

          // border-radius: 10px;

          & > div {
            padding: 10px 60px;
          }

          .pg-title {
            border-bottom: 1px solid #efefef;
          }

          .calculator-input {
            width: 85%;

            .btn-secondary {
              width: 100%;
              background: #57c7f3;
              color: white;
              border: none;
            }
          }

          .calculator-results-wrapper {
            .pg-label {
              line-height: 1em;
              height: 2em;
            }

            .calculator-results {
              border-top: 1px solid #efefef;

              .alert {
                margin-top: 5px;
                padding-top: 10px;
                padding-bottom: 10px;
                border-radius: 2px;
                margin-bottom: 0;
              }

              .alert-danger {
                color: red;
              }
            }
          }
        }

        .btn-primary {
          box-sizing: border-box;
          border-radius: 5px;
          width: 100%;
        }
      }
    }
  }

  .package-category {
    & > .card > .card-body {
      padding: 0;

      .test-list:first-child {
        border-right: 1px solid #efefef;
      }

      .card-header {
        background: #a7cef1;
        border-radius: 0;
      }
    }
  }

  @media only screen and (max-width: 1200px) {
    .package-content {
      .package-fee-calculator-outer {
        .package-fee-calculator-inner {
          .package-fee-calculator {
            & > div {
              padding: 10px;
            }
          }
        }
      }
    }
  }

  @media only screen and (max-width: 760px) {
    .package-content {
      width: 96%;

      .package-detail {
        .package-item {
          padding: 15px;
        }
      }
    }
  }
}
