.order-detail-card {
  .order-detail-card-body {
    .order-detail-items {
      .order-detail-item {
        height: 150px;

        box-sizing: border-box;
        padding: 20px 0;
        border-bottom: 1px solid black;

        &:last-child {
          border-bottom: none;
        }

        .order-items-list {

          .order-item {
            border-bottom: 1px solid #D3D3D3;

            .order-item-img {
              width: 100%;
              overflow: hidden;

              .img-div {
                aspect-ratio: 1/1;
                overflow: hidden;

                img {
                  width: 100%;
                  height: 100%;
                  object-fit: contain;
                }
              }
            }
          }
        }

        @media screen and (max-width: 768px) {
          margin: 5;
          padding: 10;

          h4,
          h5,
          span,
          div {
            margin: 10;
            padding: 0;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  .image {
    display: block;
  }
}

@media screen and (max-width: 400px) {
  .order-item-name h4 {
    font-size: 18px;

  }

  .order-item-price {
    margin: 8px;
  }

  .order-item-price h5 {
    font-size: 16px;
  }
}
