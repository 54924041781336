.order-cancel-items {
  .order-cancel-card {
    .order-cancel-row {
      .order-cancel-reason {

      }

      .order-items {
        .order-item {
          border: none;
          height: 120px;
          margin-bottom: 10px;

          &:last-child {
            margin-bottom: 0;
          }

          .item-image {
            .img-container {
              height: 100%;

              img {
                height: 100%;
                object-fit: cover;
              }
            }
          }
        }
      }
    }
  }
}

.order-cancel-body {
  background-color: #e1e3e4;
}
