.about-page {
  .inner-package {
    background-image: linear-gradient(
        292deg,
        rgba(20, 69, 128, 0.81) 0%,
        rgba(20, 69, 128, 0.85) 100%
      ),
      url(https://doctorsoncall.com.np/wp-content/uploads/2020/09/business-03-1.jpg) !important;
    background-repeat: no-repeat;
    background-size: cover;
    color: white !important;

    h1 {
      padding: 5px 0;
    }
  }

  .about-us-section {
    background: #f9f9f9;
  }

  .about-slogan-container {
    height: 400px;
  }

  .about-slogan {
    .slogan-title {
      h1 {
        font-weight: 700;
        text-align: left;
      }
    }

    .slogan-desc {
      text-align: justify;

      p {
        font-family: "Montserrat", Helvetica, Arial, Lucida, sans-serif;
        font-size: 16px;
        color: #666;
        // -webkit-font-smoothing: antialiased;
        font-weight: 500;
        line-height: 1.7em;

        text-align: justify !important;
      }
    }
  }

  .about-image {
    width: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 80%;
      height: 90%;
      object-fit: cover;
    }
  }

  .drive-us {
    background: linear-gradient(120deg, rgba(192, 223, 246, 0.95) 30%, #57c7f3);
    color: #000;

    .drive-us-title {
      font-size: 24px;
      font-weight: 700;
      margin-bottom: 15px;
    }

    .about-icons {
      font-size: 22px;
    }

    .drive-us-desc {
      p {
        color: black !important;
      }

      li {
        font-size: 16px;
        line-height: 1.4em;
        margin-bottom: 10px;
        font-family: "Montserrat", Helvetica, Arial, Lucida, sans-serif;
      }
    }
  }
}

@media screen and (max-width: 740px) {
  .about-slogan-container {
    height: auto !important;
  }
}

.mr-3 {
  margin-right: 16px;
}
