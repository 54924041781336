.auth .card .form p {
    color: #bf1650;
    margin-bottom: 10px;
    margin-top: 0;
}

.auth .card .form p::before {
    display: inline;
    content: "⚠ ";
}

.register .PhoneInputCountry, .PhoneInputInput {
    padding: .375rem 2rem;
    height: 50px;
    border: 0.906977px solid rgba(102, 102, 102, 0.35);
    border-radius: 10.8837px;
    margin-bottom: 15px;
}

.register .PhoneInputInput:focus {
    color: #212529;
    background-color: #fff;
    border-color: #86b7fe;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
}

.register-tab {
    background-color: #0259A7;
    box-shadow: 6px 9px 18px rgba(2, 89, 167, 0.1);
    border-radius: 61px;
}

.register-tab .nav-item .nav-link {
    border-radius: 67px;
    font-weight: 600;
    color: #fff;
    font-size: 19px;
    line-height: 120%;
}

.register-tab .nav-item .nav-link.active {
    border-radius: 67px;
    background-color: #fff;
    color: #0259A7;
    font-weight: 600;
}

.register h6:nth-child(1) {
    font-weight: 600;
}

.kiHXWb {
    border: 2px dashed rgba(102, 102, 102, 0.35) !important;
    background-color: #F8F8FF;
    height: 100px !important;
    padding: 10px;
    flex-direction: column;
}

.kiHXWb > input {
    display: none !important;
}

.kiHXWb svg {
    margin-left: auto;
    margin-right: auto;
}

.fVfcRr {
    flex-direction: column;
    justify-content: center !important;
    text-align: center;
}

.fVfcRr .file-types {
    max-width: 200px !important;
}

.imgUpload {
    width: 100%;
}

.imgUpload img {
    height: 100%;
    width: 100%;
    object-fit: contain;
}

.viewFileUpload iframe, .imgUpload {
    height: 150px;
}

.password-toggleable .form-control {
    border-bottom-right-radius: 6px !important;
    border-top-right-radius: 6px !important;
}
