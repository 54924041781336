.gd-store {
  font-size: 100%;

  .header {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 99;

    .material-symbols-outlined {
      font-size: 2.2rem !important;
    }
  }

  .header-items {
    .header-item {
      margin-left: 1.6rem;
      cursor: pointer;
    }
  }

  .sub-header-container {
    border-top: 2px solid #F5F5F5;
  }

  .sub-header-wrapper {
    transform: scale(1);
  }

  .sub-header {
    display: flex;

    .sub-header-item {
      padding: 1rem 3rem;
      flex-shrink: 0;
      font-size: 1.1rem;
      cursor: pointer;
      position: relative;

      &:first-child {
        padding-left: 0;
      }


    }

    .sub-header-item-link {
      &.active {
        span {
          color: #52C8F4 !important;
        }
      }
    }


  }

  .sub-header-inner-wrapper {
    display: none;
    position: relative;

    &.active {
      display: block;
      // opacity: 1;
    }
  }

  .sub-header-cover {
    width: 100%;
    height: 100vH;
    background: black;
    opacity: 0.2;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
  }

  .sub-header-inner {
    border-top: 2px solid #F5F5F5;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    display: none;
    z-index: 4;


    .sub-header-inner-item-wrap {
      background: white;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    }

    .sub-header-inner-item {
      display: flex;
    }

    ul {
      width: 30%;
      border-right: 2px solid #F5F5F5;
      list-style: none;
      padding: 0 2rem;
      line-height: 2.4rem;

      li {
        a:hover {
          color: #52C8F4 !important;
          cursor: pointer;
        }
      }
    }

    &.active {
      display: block;
      z-index: 2;
    }
  }

}

@media screen and (max-width: 730px) {
  .gd-store {
    font-size: 100% !important;
  }


  .sub-header-container {
    display: none;
  }
}

