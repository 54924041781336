.ad-container {
  padding: 0;
  margin: 0;
  max-height: 1200px;
  width: 100%;
  background: white;
  cursor: pointer;

  .ad-gif-container {
    width: 100%;
    height: 100%;
    background: white;

    img {
      width: 100%;
      height: 100%;
      object-fit: fill;
    }
  }
}
