.dashboard-home {
  .card {
    border: none;
    border-radius: 10px;
  }

  .dashboard-banner {
    background-color: #585ce5;
    background-image: url(../../../../images/illustration.png);
    background-repeat: no-repeat;
    background-size: auto 85%;
    background-position-x: right;
    background-position-y: bottom;

    .card-body {
      background-color: rgba($color: #000000, $alpha: 0.3);
    }

    .btn-primary {
      background-color: #063b9d !important;
      border-color: #063b9d !important;
      padding: 10px 40px;
    }
  }

  .profile-card {
    .card-header {
      padding-bottom: 0;
    }

    .card-body {
      padding-bottom: 0;
    }
  }

  .profile-top {
    display: inline-block;
  }

  .profile-image {
    width: 20%;

    img {
      width: 100%;
      aspect-ratio: 1/1;
      object-fit: contain;
      border-radius: 50%;
    }
  }

  .icon-image {
    height: 55px;

    img {
      height: 100%;
      object-fit: fill;
    }
  }
}

.enquiry-btn {
  margin-top: 10px;
}

@media screen and (min-width: 991px) {
  .enquiry-btn {
    margin-left: 10px;
    margin-top: 0px;
  }
}

.career-btn,
.enquiry-btn {
  background-color: transparent;
  border: 1px solid #063b9d;
  border-radius: 8px;
  padding: 8px 24px !important;
  text-transform: uppercase !important;

  &:hover {
    background-color: #063b9d;
    color: white !important;
  }
}
