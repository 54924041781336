.order-items {
  .MuiBox-root.css-19kzrtu {
    padding: 0 !important;
  }
}

.order-list {

  .order-card {

    .card {

      .card-header {
        @media screen and (max-width: 768px) {
          font-size: 15px;
        }
      }

      .card-body {

        .order-products-list {

          .order-product {
            height: 100px;
            border-bottom: 1px solid #D3D3D3;

            .order-product-img {
              width: 100%;
              overflow: hidden;

              .img-div {
                aspect-ratio: 1/1;
                overflow: hidden;

                img {
                  width: 100%;
                  height: 100%;
                  object-fit: contain;
                }
              }
            }

            @media screen and (max-width: 768px) {
              font-size: 14px !important;

              .btn {
                font-size: 14px !important;
              }
            }
          }
        }

        @media screen and (max-width: 768px) {
          margin: 0;
          padding: 0;

          h2, h3, h4, h5, span, div {
            margin: 0;
            padding: 0;
          }
        }
      }
    }
  }
}
