.slick-3d {
  // text-align: center;
  width: 100%;
  // margin: 10rem auto;
  /* height: 570px; */

  .slide img {
    width: 20rem;
    margin: 0 auto;
  }

  .slider-item {
  }

  .slide {
    transform: scale(0.8);
    transition: transform ease-out 100ms;
    opacity: 0.8;
    z-index: -1;
    padding: 60px 0;
    height: 100%;
    background: white;
    background-size: cover;
    background-repeat: none;
    background-position: center;
    border-radius: 10px;
  }

  .activeSlide {
    transform: scale(1.01);
    opacity: 1;
    z-index: 2;
    background: url(../images/Gradient1.png) !important;
    background-size: cover;
    background-repeat: none;
    background-position: center;
    color: white;
    border-radius: 10px;
    padding-top: 40px !important;
  }

  .arrow {
    background-color: #fff;
    position: absolute;
    cursor: pointer;
    z-index: 10;
  }

  .arrow svg {
    transition: color 300ms;
  }

  .arrow svg:hover {
    color: #68edff;
  }

  .next {
    right: 0%;
    top: 50%;
  }

  .prev {
    left: 0%;
    top: 50%;
    background-color: none;
  }

  .slick-track {
    display: flex !important;
  }

  .slick-slide {
    height: inherit !important;
  }
}

.slick-3d .arrow {
  background: none;
  height: 50px;
  width: 50px;
  text-align: center;
  line-height: 50px;
  background: white;
  border-radius: 50%;
}

.slick-3d .next {
  right: -20px;
}

.slick-3d .prev {
  left: -20px;
}

.max-w-500px {
  max-width: 500px;
}
