.carouselSlickSlider {
  .slick-slider {
    .slick-dots {
      // position: 'absolute';
      bottom: 15px !important;

      li {
        button::before {
          font-size: 15px;
          font-weight: bold;
          // color:white;
        }
      }
    }
  }
}
