.prod-page-container > .card {
    border: none;
}

.prod-desc-img-card {
    width: 100%;
    overflow: hidden;
}

.prod-desc-main-img {
    aspect-ratio: 1/1;
    overflow: hidden;
}

.prod-desc-img-card .img-div {
    margin: auto;
    width: 100%;
    height: 100%;
    overflow: hidden;
    /* aspect-ratio:1/1; */
    position: relative;
    display: flex;
    align-items: center;
}

.prod-desc-img-card .img-div > div {
    width: 100% !important;
    height: 100% !important;
}

.prod-desc-img-card .img-div > div img {
    width: 100% !important;
    height: 100% !important;
    object-fit: contain;
}

.prod-details {
    padding: 15px 5px;
}

.prod-details div {
    margin-bottom: 15px;
}

.prod-img-slide .img-div {
    width: 20%;
    aspect-ratio: 1/1;
}

.prod-img-slide .img-div img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.prod-details-title h3 {
    text-align: left;
    font: normal normal 600 26px/40px;
    font-weight: 600;
    letter-spacing: 0;
    color: #3c3c3c;
}

.prod-details-btn-group button {
    border: none;
    padding: 10px 0;
    width: 160px;
    margin-right: 10px;
    /* box-shadow: 0 1px 2px black !important; */
}

.prod-details-btn-group-add-cart button {
    background-color: #559EFC;
    color: white;
}

.prod-details-btn-group-add-wishlist button {
    background-color: #DC3545;
    color: white;
}

.prod-details-btn-group button .btn-icon {
    margin-right: 10px;
}

.prod-details-btn-group button:hover {
    opacity: 0.9;
}

.prod-details-tab-bar .selected {
    color: #5DA3FC;
    border-bottom: 3px solid #5DA3FC;
}

.tab-bar-item {
    padding: 6px 15px;
    cursor: pointer;
}

.tab-bar-item:hover {
    color: #5DA3FC;
}

#img-zoomer {
    width: 100px;
    height: 100px;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 99;
    background: black;
    opacity: 0.5
}

.magnified-image {
    z-index: 99;
    background-color: #fff;
}

.item-count-stepper {
    max-width: 150px;
}
