.package-category {
    font-size: 20px;
    font-weight: 600;
}

.package-category svg {
    color: #0259A7;
}

.silver-text {
    color: #335A73;
    font-weight: 500;
    font-size: 18px;
}

.display-footer {
    font-size: 30px;
    font-weight: 600;

}

.package-btn {
    margin-left: 950px;
    font-size: 18px;
    font-weight: 600;
}

/* .package-container
{
    position: sticky;
} */
