@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@100;200;300;400;600;700&display=swap");
* {
  font-family: "Source Sans Pro", sans-serif;
}

code {
  color: red;
  font-size: 16px;
}

h1,
h2,
h4,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

#root {
  min-height: 100vh;
}

.weblayout-style {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.transition-container .icon-pop-transition {
  transition: all 300ms;
}

.transition-container:hover .icon-pop-transition {
  box-shadow: 0 0 20px 10px #00b6fb;
  transform: scale(0.8);
}

.weblayout-style .footer {
  margin-top: auto;
}

.weblayout-style .container,
.weblayout-style .container-lg,
.weblayout-style .container-sm,
.weblayout-style .container-md,
.page-loaded {
  animation: transitionIn 0.75s;
}

@keyframes transitionIn {
  from {
    opacity: 0;
    transfrom: rotateX(-10deg);
  }

  to {
    opacity: 1;
    transform: rotateX(0);
  }
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.input-num-no-arrow input::-webkit-outer-spin-button,
.input-num-no-arrow input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.input-num-no-arrow input[type="number"] {
  -moz-appearance: textfield;
}

.password-eye-hide input::-ms-reveal {
  display: none;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.label-fix .MuiInputBase-formControl {
  margin-top: 0 !important;
}

.payment-details .MuiAccordionSummary-content.Mui-expanded {
  margin: 12px 0 !important;
}

.payment-details .css-1c35hjw-MuiPaper-root-MuiAccordion-root.Mui-expanded {
  margin: 0;
}

.payment-details .css-1c35hjw-MuiPaper-root-MuiAccordion-root::before {
  display: none;
}

.text-warning {
  color: yellow;
}

.text-danger {
  color: red;
}
