.heading p:nth-child(1) {
    font-weight: 600;
    font-size: 25px;
    line-height: 100%;
    color: #0259A7;
}

.heading p:nth-child(2) {
    font-weight: 400;
    line-height: 100%;
    color: #000000;
}

.btn-outline-dark {
    border-width: 2px;
}

.services-btn {
    border-radius: 37px;
    padding: 6px 15px;
    font-weight: 600;
}

.services-btn:hover {
    color: #fff;
}

.main-services .card {
    border-radius: 18px;
    border: none;
    background-color: inherit;
}

.main-services .card-img-top {
    border-top-left-radius: 18px;
    border-top-right-radius: 18px;
}

.main-services .card-body {
    border-radius: 18px;
    padding: 20px 15px;
    border-bottom-left-radius: 18px;
    border-bottom-right-radius: 18px;
    /* background: linear-gradient(79.17deg, #57C7F3 2.81%, #0259A7 118.62%); */
    height: 340px;
}

.main-services .card-body .service-icon {
    width: 30%;
    aspect-ratio: 1/1;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.main-services .card-body .service-icon img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.main-services .card-body .service-desc-contain-top {
    height: 90px;
}

.main-services .card-body .service-title-outer {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.main-services .card-body .service-title {
    font-size: 22px;
    line-height: 25px;
    font-weight: 600;
    text-align: left !important;
}

.main-services .card-body .service-desc {
    /* height: 50px; */
    font-size: 14px;
    line-height: 16px;
    color: #FFFFFF;
    text-align: justify;
    opacity: 0.8;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    /* number of lines to show */
    line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.main-services .card-body .service-read-more {
    height: 30px;
    font-weight: 600;
}

.main-services .shadow {
    box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .25) !important;
}

.main-services h4 {
    font-size: 24px;
}

/* .main-services .card p {
    font-size: 18px;
    color: #FFFFFF;
    opacity: 0.8;
} */
