.gd-store {
  .breadcrumb {
    font-size: 1.1rem;

    p {
      font-size: 1.1rem;
      color: #0d59a8;
      font-weight: 500;
    }
  }
}

hr {
  all: unset;
}

.gd-store-coming-soon {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  height: 80vh;
  font-family: "Poppins";
  text-align: center;

  .logo {
    align-self: center;
  }

  .coming-soon {
    margin-top: 40px;
    align-self: center;
  }

  span {
    font-size: 66px;
    font-weight: 600;
    font-family: "Poppins";
  }

  span:first-child {
    color: #0e59a6;
  }

  span:nth-child(2) {
    color: #50c9f6;
    margin-left: 10px;
  }

  hr {
    border-top: 2px solid;
    border-color: #50c9f6;
    margin-top: 0px;
  }

  p {
    font-size: 20px;
    line-height: normal;
    font-family: "Poppins";
    opacity: 0.7;
    align-self: center;
    margin-top: 22px;
    max-width: 500px;
  }

  a {
    background-color: #0071bd;
    outline: none;
    border: none;
    color: white;
    padding: 16px 20px;
    border-radius: 6px;
    font-size: 14px;
    width: 200px;
    text-transform: uppercase;
    text-decoration: none;
    font-weight: 500;
    align-self: center;
    margin-top: 40px;
  }
}
