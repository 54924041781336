.screening_tab {
    background-color: #1976d2 !important;
}

.screening_tab .nav-link {
    color: #fff !important;
}

.screening_tab .nav-link:hover {
    color: #c6dff6 !important;
}

.screening_tab .nav-link.active {
    background-color: #c6dff6 !important;
    border-radius: 0.5rem;
    color: #252525 !important;
}

.screening_tab .nav-link {
    padding-top: 8px;
    padding-bottom: 8px;
}
