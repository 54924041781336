.vendor-store-header {
  .card {
    .card-header {
      background: url("https://img.freepik.com/free-vector/dna-sign-medical-healthcare-banner_1017-24287.jpg?w=2000") no-repeat center;
      background-size: cover;
      border: none;

      @media screen and (max-width: 425px) {
        font-size: 12px !important;
        color: white !important;

        .vendor-details {
          background: none !important;
        }

        h4 {
          color: white;
          font-size: 18px !important;
          margin: 0;
          padding: 0;
        }

        .vendor-rating {
          color: white;
          font-size: 18px !important;
        }

        .vendor-bg > div {
          margin: 0;
          padding: 0;
        }

        .text-success {
          color: white !important;
          // font-weight: bold;
        }

        .vendor-detail-prod-count .text-primary {
          color: white !important;
          font-weight: bold;
        }
      }

      .vendor-details {
        background: #fff;
        width: 80%;
        aspect-ratio: 7/1;
        margin: auto;

        @media screen and (max-width: 1024px) {
          width: 100%;
        }

        .vendor-details-logo {
          width: 100%;
          height: 100%;

          .vendor-logo-ic {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;

            img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }
        }
      }
    }
  }
}
