.category-container {
  h3 {
    margin: 0
  }

  .category-card {
    cursor: pointer;
    width: 100%;
    border: none;
    box-shadow: 0 2px 10px #D2D2D2;
    aspect-ratio: 1/1;

    &:hover {
      opacity: 0.7;
    }

    .card-header {
      height: 70%;
      padding: 0;
      border: none;
      padding: 0;
      margin: 0;

      .img-div {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }

    .card-body {
      height: 30%;
      overflow: hidden;

      @media screen and (max-width: 425px) {
        font-size: 10px !important;
      }
    }
  }
}
