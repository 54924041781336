.typing-animation {
    display: inline-flex;
}

.typing-animation .dot {
    width: 10px;
    height: 10px;
    background: #6c757d;
    border-radius: 50%;
    margin: 0 3px;
    opacity: 0.7;
    animation: animDots 1.8s var(--delay) ease-in-out infinite;
}

@keyframes animDots {

    0%,
    44% {
        transform: translateY(0px);
    }

    28% {
        transform: translateY(-9px);
        opacity: 0.4;
    }

    44% {
        opacity: 0.2;
    }
}

.chat-floating-btn {
    overflow: hidden !important;
    animation: blinking 1s ease infinite;
}

@keyframes blinking {
    0% {
        border: 5px solid #0259A7;
    }

    100% {
        background-color: #0259A7;
        border: 5px solid #57c7f3;
    }
}