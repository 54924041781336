.footer {
    background-color: #C6DFF6;
    /* background: #85a9df; */
    /* background: linear-gradient(306deg, rgba(82,200,244,1) 0%, rgba(198,223,246,1) 96%); */
}

.logo {
    max-width: 60px;
}

.logo-text {
    font-weight: 600;
}

.pages h4 {
    font-weight: 700;
}

.pages a {
    text-decoration: none;
    color: black;
}

.pages a:hover {
    color: black;
    opacity: 0.7;
    transition: 0.2s all;
}
