.booking .heading, .booking .search {
    background-color: #E9ECF4;
}

.booking .heading {
    padding: 35px 0px;
}

.booking .heading h3 {
    font-weight: 600;
}

.booking .heading p, .booking .schedule-part p {
    color: #001221;
    opacity: 0.7;
}

.booking label {
    margin-bottom: 5px;
    font-weight: 600;
}

.booking .search, .booking .heading, .booking .schedule-part .header {
    border-bottom: 1px solid #D9D9D9;
}

.booking .doctor-part {
    background-color: #E9ECF4;
    border: 1.28986px solid rgba(0, 18, 33, 0.14);
    box-sizing: border-box;
}

.booking .doctor-img {
    width: 100%;
    max-width: 120px;
    aspect-ratio: 1/1;
    overflow: hidden;
    object-fit: cover;
    border-radius: 50%;
}

.booking h5 {
    color: #0259A7;
    font-weight: 600;
}

.booking .doctor-part ul li {
    line-height: 170%;
}

.booking .doctor-part ul .time {
    color: #0259A7;
    font-weight: 600;
    font-size: 16px;
}

.booking .schedule-part h6, .booking .schedule-part p {
    font-weight: 600;
}

.booking .schedule-part p {
    font-size: 14px;
}

.booking .time-slot {
    border-right: 1px solid #D9D9D9;
}

.booking .btn-info {
    background-color: #C6DFF6;
    border-color: #C6DFF6;
    color: #0259A7;
}

.booking .nav-link {
    color: #0259A7;
}

/* .booking .react-datepicker__header{
    padding-top:10px;
    padding-bottom:10px;
} */

.date-picker .react-datepicker__header {
    background-color: #C6DFF6 !important;
    border-radius: 0 !important;
}

.date-picker .react-datepicker {
    display: block !important;
    border-radius: 0 !important;
}

.date-picker .react-datepicker__month-container {
    float: none !important;
}

.date-picker .react-datepicker__month {
    margin: 10px !important;
}

.date-picker .react-datepicker__day--selected {
    background-color: #0259A7 !important;
}

.date-picker .react-datepicker__navigation-icon::before {
    border-color: #001221 !important;
}

.date-picker .react-datepicker__navigation:hover *::before {
    border-color: #001221 !important;
    opacity: 0.7;
}

.search .react-datepicker-wrapper {
    width: 100%;;
}

.search input {
    border: 1px solid #ced4da !important;
    padding: .375rem .75rem;
    border-radius: .375rem;
}

.search input:focus {
    color: #212529;
    background-color: #fff;
    border-color: #86b7fe;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
}

.time-filter .react-datepicker__input-time-container .react-datepicker-time__caption, .search .react-datepicker__input-time-container .react-datepicker-time__caption {
    font-size: 16px;
    font-weight: 600;
}

.search .react-datepicker__input-container input {
    width: 100% !important;
}

.search .react-datepicker-popper {
    z-index: 2;
}
