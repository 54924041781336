.checkout-card-header {
    background: #E9ECF4;
}

.order-items-container {
    padding-right: 10px;
    max-height: 310px;
    overflow-y: scroll;
    overflow-x: hidden;
}

.order-item-desc {
    width: 90%;
}

.order-item {
    height: 150px;
    border: 2px solid #D3D3D3;
}

.logo-color-1 {
    color: #0D59A8;
}

.checkout-container .img-div {
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.checkout-container .img-div img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.order-item-count input {
    width: 50px !important;
    padding: 2px !important;
}

.checkout-summary-details {
    border: 1px solid #D3D3D3;
    padding: 10px 20px;
    width: 100%;
}

.phone-input input {
    width: 100% !important;
}
